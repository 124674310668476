<!-- This is the file that will render any external pages you do not want inside the dash, like login form-->
<template>
  <v-layout style="min-height: 90vh">
    <v-flex style="">
      <v-card class="pa-2" style="">
        <v-toolbar color="blue" class="ma-2" style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;">
          <div style="min-width:100%!important;display:flex;justify-content:space-between;">

            <span class="toolbar-title" style="width:80%;padding:10px;"> Configurações </span>
            <span style="width:5%;padding:10px;"> 
              <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Configurações"/>
            </span>

          </div>
        </v-toolbar>
        <v-expansion-panels class="my-10" style="width: 90%; max-width: 1024px; margin: 0 auto;">


          <!-- Painel para configuração das mensagens automaticas ao clientes do contratante -->
          <v-expansion-panel class="my-2">
              <v-expansion-panel-header>
              <v-row no-gutters>
                  <v-col cols="4">
                  Mensagens aos clientes
                  </v-col>
              </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
              <v-row no-gutters>
                  <v-col cols="12">
                    <!-- Texto do Email disparado Automaticamente no Aniversario -->
                    <div style="display: flex; justify-content: center; margin: 1em 0;">
                      <v-card class="pa-2" style="width:100%;display:flex;">
                          <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                            <h3>Texto do Email disparado Automaticamente no Aniversario</h3>
                          </div>
                          <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                            <v-btn @click="setModalEmailAniversario()" data-cy="setModalEmailAniversario" color="primary" style="color:white;font-weight:bold;">
                              Editar
                            </v-btn>
                          </div>
                      </v-card>    
                    </div>	
                    <!-- Mensagens de Feliz Aniversário WhatsApp -->
                    <div style="display: flex; justify-content: center; margin: 1em 0;">
                      <v-card class="pa-2" style="width: 100%; display: flex">
                        <div
                          style="
                            width: 70%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                          "
                        >
                          <h3>Mensagens de Feliz Aniversário WhatsApp</h3>
                        </div>
                        <div
                          style="
                            width: 30%;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                          "
                        >
                          <v-icon
                            color="green"
                            v-if="isWhatsAppSet"
                            title="A API está configurada/ativa."
                            >mdi-check</v-icon
                          >
                          <v-icon
                            color="orange"
                            v-if="!isWhatsAppSet"
                            title="A API NÃO está configurada/ativa."
                            >mdi-exclamation</v-icon
                          >
                          <v-btn
                            :disabled="!isWhatsAppSet"
                            @click="setModalEditarFelizAniversarioWhats()"
                            data-cy="setModalEditarFelizAniversarioWhats"
                            style="color:white;font-weight:bold;" 
                            color="primary"
                          >
                            Editar
                          </v-btn>
                        </div>
                      </v-card>
                    </div>
                    <!-- Galeria da Assinatura -->
                    <div style="display: flex; justify-content: center; margin: 1em 0;">
                      <v-card class="pa-2" style="width:100%;display:flex;">
                        <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                          <h3>Galeria de Imagens na Assinatura</h3>
                        </div>
                        <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                          <v-btn @click="setModalGaleriaAssinatura()" data-cy="setModalGaleriaAssinatura" style="color:white;font-weight:bold;" color="primary">
                            Editar
                          </v-btn>
                        </div>
                      </v-card>
                    </div>
                  </v-col>
              </v-row>
              </v-expansion-panel-content>
          </v-expansion-panel>  
          
          <!-- Painel para configuração dos termos -->
          <v-expansion-panel class="my-2">
              <v-expansion-panel-header>
              <v-row no-gutters>
                  <v-col cols="4">
                  Termos
                  </v-col>
              </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
              <v-row no-gutters>
                  <v-col cols="12">
                    <!-- Termo do Hotel -->
                    <div style="display: flex; justify-content: center; margin: 1em 0;">
                      <v-card class="pa-2" style="width:100%;display:flex;">
                          <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                            <h3>Termo do Hotel</h3>
                          </div>
                          <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                            <v-btn @click="setModalEditarTermo()" data-cy="setModalEditarTermo" color="primary" style="color:white;font-weight:bold;">
                              Editar
                            </v-btn>
                          </div>
                      </v-card>
                    </div>

                    <!-- Termo LGPD -->
                    <div style="display: flex; justify-content: center; margin: 1em 0;">
                      <v-card class="pa-2" style="width:100%;display:flex;">
                          <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                            <h3>Termo LGPD</h3>
                          </div>
                          <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                            <v-btn @click="setModalEditarTermoLGPD()" data-cy="setModalEditarTermoLGPD" color="primary" style="color:white;font-weight:bold;">
                              Editar
                            </v-btn>
                          </div>
                      </v-card>
                    </div>
                  </v-col>
              </v-row>
              </v-expansion-panel-content>
          </v-expansion-panel>  

          <!-- Formulário Sanitário (Recepção e Web Checkin) -->
          <!-- <div style="display:flex;justify-content:center;">
            <v-row class="ma-2" style="max-width: 70%;" >
              <v-card class="pa-2" style="width:100%;display:flex;">
                  <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                    <h3>Formulário Sanitário (Recepção e Web Checkin)</h3>
                  </div>
                  <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                    <v-btn @click="setModalEditarFormSanitario()" data-cy="setModalEditarFormSanitario" color="primary">
                      Editar
                    </v-btn>
                  </div>
              </v-card>    
              
            </v-row>
          </div>		 -->
        
          <!-- Painel para configuração do WebCheckin -->
          <v-expansion-panel class="my-2">
            <v-expansion-panel-header>
            <v-row no-gutters>
                <v-col cols="4">
                  Configurações Web Checkin
                </v-col>
            </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            <v-row no-gutters>
                <v-col cols="12">
                  <!-- Configurações Web Checkin -->
                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Web Checkin Geral</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalEditarPassosWebCheckin()" data-cy="setModalEditarPassosWebCheckin" 
                          style="color:white;font-weight:bold;" 
                          color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>	

                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Envios Automáticos</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalFollowUpWebCheckin()" data-cy="setModalFollowUpWebCheckin" 
                          style="color:white;font-weight:bold;" 
                          color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>	

                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Envios Boas Vindas</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalBemVindos()" data-cy="setModalBemVindos" 
                          style="color:white;font-weight:bold;" 
                          color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>

                  <!-- Pergunta Extra no WebCheckin -->
                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Pergunta Extra</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalEditarPassosWebCheckinPergunta()" data-cy="setModalEditarPassosWebCheckinPergunta" style="color:white;font-weight:bold;" color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>            
                  </div>
                </v-col>
            </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>  

          <v-expansion-panel class="my-2">
            <v-expansion-panel-header>
            <v-row no-gutters>
                <v-col cols="4">
                  Configurações Checkin Pay
                </v-col>
            </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            <v-row no-gutters>
                <v-col cols="12">
                  <!-- Configurações Web Checkin -->

                  

                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                       <h3>Configurações de Gateways do Checkin Pay</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="gotoGatewaysCheckins()" data-cy="setModalEditarPassosWebCheckin" 
                          style="color:white;font-weight:bold;" 
                          color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>	

                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                       <h3>Configurações Credito | Débito | Pix</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalDebCredPix()" data-cy="setModalEditarPassosWebCheckin" 
                          style="color:white;font-weight:bold;" 
                          color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>
                  

                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                       <h3>Envios Automaticos de Checkin Pay</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalEditarEnvioEmail()" data-cy="setModalEditarPassosWebCheckin" 
                          style="color:white;font-weight:bold;" 
                          color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>	
                  
                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                       <h3>Configurações Gerais</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalEditarCheckinPayGeral()" data-cy="setModalEditarPassosWebCheckin" 
                          style="color:white;font-weight:bold;" 
                          color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>	

                  <!-- <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Web Checkin Geral</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalEditarPassosWebCheckin()" data-cy="setModalEditarPassosWebCheckin" 
                          style="color:white;font-weight:bold;" 
                          color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>	

                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Envios Automáticos</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalFollowUpWebCheckin()" data-cy="setModalFollowUpWebCheckin" 
                          style="color:white;font-weight:bold;" 
                          color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>	

                 
                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Pergunta Extra</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalEditarPassosWebCheckinPergunta()" data-cy="setModalEditarPassosWebCheckinPergunta" style="color:white;font-weight:bold;" color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>            
                  </div> -->
                </v-col>
            </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>  
          
          <!-- Configurações de Email -->
          <!-- <div style="display: flex; justify-content: center">
            <v-row class="ma-2" style="max-width: 70%">
              <v-card class="pa-2" style="width: 100%; display: flex">
                <div
                  style="
                    width: 70%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                  "
                >
                  <h3>Configurações de Email</h3>
                </div>
                <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                  <v-btn @click="setModalEditarPassosWebCheckin()" data-cy="setModalEditarPassosWebCheckin" color="primary">
                    Editar
                  </v-btn>
                </div>
              </v-card>
            </v-row>
          </div>	 -->
          
          <!-- Pergunta Extra no WebCheckin -->
          <!-- <div style="display:flex;justify-content:center;">
            <v-row class="ma-2" style="max-width: 70%;" >
              <v-card class="pa-2" style="width:100%;display:flex;">
                <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                  <h3>Pergunta Extra no WebCheckin</h3>
                </div>
                <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                  <v-btn @click="setModalEditarPassosWebCheckinPergunta()" data-cy="setModalEditarPassosWebCheckinPergunta" style="color:white;font-weight:bold;background-color:#2196F3;" color="primary">
                    Editar
                  </v-btn>
                </div>
              </v-card>    
              
            </v-row>
          </div> -->
          
          <!-- Painel para configuração dos meios de comunicação do contratante do ficha -->
          <v-expansion-panel class="my-2">
            <v-expansion-panel-header>
            <v-row no-gutters>
                <v-col cols="4">
                  Dados Comunicação Contratante
                </v-col>
            </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            <v-row no-gutters>
                <v-col cols="12">
                  <!-- Configurações de Email -->
                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width: 100%; display: flex">
                      <div
                        style="
                          width: 70%;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                        "
                      >
                        <h3>Email</h3>
                      </div>
                      <div
                        style="
                          width: 30%;
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                        "
                      >
                        <v-btn
                          @click="setModalEditarContaEmail()"
                          data-cy="setModalEditarContaEmail"
                          color="primary"
                          style="color:white;font-weight:bold;" 
                        >
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>	

                  <!-- Configurações WhatsApp -->
                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width: 100%; display: flex">
                      <div
                        style="
                          width: 70%;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                        "
                      >
                        <h3>WhatsApp</h3>
                      </div>
                      <div
                        style="
                          width: 30%;
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                        "
                      >
                        <v-btn
                          @click="setModalEditarAPIWhatsApp()"
                          data-cy="setModalEditarAPIWhatsApp"
                          color="primary"
                          style="color:white;font-weight:bold;" 
                        >
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>
                </v-col>
            </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>  

          <!-- Painel para configuração das importações -->
          <v-expansion-panel class="my-2">
            <v-expansion-panel-header>
            <v-row no-gutters>
                <v-col cols="4">
                  Integrações
                </v-col>
            </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            <v-row no-gutters>
                <v-col cols="12">
                  
                  <!-- Gerais -->
                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Gerais</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalGerais()" data-cy="setModalGerais" style="color:white;font-weight:bold;" color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>

                  <!-- Configuração de Importações Úteis -->
                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Importações Úteis</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalGeo()" data-cy="setModalGeo" style="color:white;font-weight:bold;" color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>    
                  </div>

                  <!-- HITS -->
                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Configurações HITS</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalHITS()" data-cy="setModalHITS" style="color:white;font-weight:bold;" color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>

                  <!-- Webcheckout -->
                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Webcheckout</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="setModalWebcheckout()" data-cy="setModalWebcheckout" style="color:white;font-weight:bold;" color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>
                  </div>

                </v-col>
            </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>  

          <!-- Painel para configurações extras -->
          <v-expansion-panel class="my-2">
            <v-expansion-panel-header>
            <v-row no-gutters>
                <v-col cols="4">
                  Configurações Extras
                </v-col>
            </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            <v-row no-gutters>
                <v-col cols="12">
                  <!-- Opcionais -->
                  <div style="display: flex; justify-content: center; margin: 1em 0;">
                    <v-card class="pa-2" style="width:100%;display:flex;">
                      <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                        <h3>Opcionais</h3>
                      </div>
                      <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                        <v-btn @click="gotoOpcionais()" data-cy="gotoOpcionais" style="color:white;font-weight:bold;" color="primary">
                          Editar
                        </v-btn>
                      </div>
                    </v-card>    
                  </div> 
                </v-col>
            </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel> 


       



          <!-- Aparência -->
          <!-- <div style="display:flex;justify-content:center;">
            <v-row class="ma-2" style="max-width: 70%;" >
              <v-card class="pa-2" style="width:100%;display:flex;">
                  <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                    <h3>Aparência</h3>
                  </div>
                  <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                    <v-btn @click="setModalEditarAparencia()" data-cy="setModalEditarAparencia" color="primary">
                      Editar
                    </v-btn>
                  </div>
              </v-card>
            </v-row>
          </div>		 -->
  
        </v-expansion-panels>


      </v-card>
    </v-flex>
	
    <ModalEditarEmailAniversario
      @setModalEmailAniversario="() => setModalEmailAniversario()"
      :exibirModalEditarEmailAniversario="exibirModalEditarEmailAniversario"
    />

    <ModalEditarTermo
      @setModalEditarTermo="() => setModalEditarTermo()"
      :exibirModalEditarTermo="exibirModalEditarTermo"
    />

    <ModalEditarEnvioEmailCheckinPay
      @setModalEditarEnvioEmail="() => setModalEditarEnvioEmail()"
      :exibirModalEditarEnvioEmail="exibirModalEditarEnvioEmail"
    />


    <ModalEditarTermoLGPD
      @setModalEditarTermoLGPD="() => setModalEditarTermoLGPD()"
      :exibirModalEditarTermoLGPD="exibirModalEditarTermoLGPD"
    />

    <ModalEditarFormSanitario
      @setModalEditarFormSanitario="() => setModalEditarFormSanitario()"
      :exibirModalEditarFormSanitario="exibirModalEditarFormSanitario"
    />
	
    <ModalEditarPassosWebCheckin
      @setModalEditarPassosWebCheckin="() => setModalEditarPassosWebCheckin()"
      :exibirModalEditarPassosWebCheckin="exibirModalEditarPassosWebCheckin"
    />

    <ModalEditarCheckinPayGeral
      @setModalEditarCheckinPayGeral="() => setModalEditarCheckinPayGeral()"
      :exibirModalEditarCheckinPayGeral="exibirModalEditarCheckinPayGeral"
    />

    <ModalFollowupWebCheckin
      @setModalFollowUpWebCheckin="() => setModalFollowUpWebCheckin()"
      :exibirModalFollowUpWebCheckin ="exibirModalFollowUpWebCheckin"
    />

    <ModalBemVindos
      @setModalBemVindos="() => setModalBemVindos()"
      :exibirModalBemVindos ="exibirModalBemVindos"
    />
			
	
    <ModalEditarPassosWebCheckinPergunta
      @setModalEditarPassosWebCheckinPergunta="() => setModalEditarPassosWebCheckinPergunta()"
      :exibirModalEditarPassosWebCheckinPergunta ="exibirModalEditarPassosWebCheckinPergunta"
    />

    
    <ModalEditarFelizAniversarioWhats
      @setModalEditarFelizAniversarioWhats="
        () => setModalEditarFelizAniversarioWhats()
      "
      :exibirModalEditarMensagemFelizAniversario="
        exibirModalEditarMensagemFelizAniversario
      "
    />

    <ModalEditarAPIWhatsApp
      @setModalEditarAPIWhatsApp="() => setModalEditarAPIWhatsApp()"
      :exibirModalEditarAPIWhatApp="exibirModalEditarAPIWhatApp"
    />

    <ModalEditarContaEmail
      @setModalEditarContaEmail="() => setModalEditarContaEmail()"
      :exibirModalEditarContaEmail="exibirModalEditarContaEmail"
    />

    <ModalEditarGeo
      @setModalGeo="() => setModalGeo()"
      :exibirModalGeo="exibirModalGeo"
    />

    <ModalHITS
      @setModalHITS="() => setModalHITS()"
      :exibirModalHITS="exibirModalHITS"
    />

    <ModalWebcheckout
      @setModalWebcheckout="() => setModalWebcheckout()"
      :exibirModalWebcheckout="exibirModalWebcheckout"
    />

    <ModalGerais
      @setModalGerais="() => setModalGerais()"
      :exibirModalGerais="exibirModalGerais"
    />

    <ModalGaleriaAssinatura
      @setModalGaleriaAssinatura="() => setModalGaleriaAssinatura()"
      :exibirModalGaleriaAssinatura="exibirModalGaleriaAssinatura"
    />    

    <ModalDebCredPixCPAY
      @setModalDebCredPix="() => setModalDebCredPix()"
      :exibirModalEditarDebCredCPAY ="exibirModalEditarDebCredCPAY"
    />

    <!-- <ModalEditarAparencia
      @setModalEditarAparencia="() => setModalEditarAparencia()"
      :exibirModalEditarAparencia="exibirModalEditarAparencia"
    /> -->
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
          <span>{{ snackText }}</span>
          <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
        </div>
    </v-snackbar>

  </v-layout>
</template>

<script>
import ModalEditarEmailAniversario from "@/components/DashViews/Modals/ModalEditarEmailAniversario";
import ModalEditarTermo from "@/components/DashViews/Modals/ModalEditarTermo";
import ModalEditarTermoLGPD from "@/components/DashViews/Modals/ModalEditarTermoLGPD";
import ModalEditarGeo from "@/components/DashViews/Modals/ModalEditarGeo";
import ModalEditarFormSanitario from "@/components/DashViews/Modals/ModalEditarFormSanitario";
import ModalEditarPassosWebCheckin from '@/components/DashViews/Modals/ModalEditarPassosWebCheckin'
import ModalEditarCheckinPayGeral from '@/components/DashViews/Modals/ModalEditarCheckinPayGeral'
import ModalFollowupWebCheckin from '@/components/DashViews/Modals/ModalFollowupWebCheckin'
import ModalBemVindos from '@/components/DashViews/Modals/ModalBemVindos'
import ModalEditarPassosWebCheckinPergunta from '@/components/DashViews/Modals/ModalEditarPassosWebCheckinPergunta'
import ModalEditarFelizAniversarioWhats from "@/components/DashViews/Modals/ModalFelizAniversarioWhats";
import ModalEditarAPIWhatsApp from "@/components/DashViews/Modals/ModalEditarAPIWhatsapp.vue";
import ModalEditarContaEmail from "@/components/DashViews/Modals/ModalEditarContaEmail.vue";
import ModalHITS from "@/components/DashViews/Modals/ModalHITS.vue";
import ModalWebcheckout from "@/components/DashViews/Modals/ModalWebcheckout.vue";
import ModalGerais from "@/components/DashViews/Modals/ModalGerais.vue";
import ModalGaleriaAssinatura from "@/components/DashViews/Modals/ModalGaleriaAssinatura.vue";
import ModalDebCredPixCPAY from "@/components/DashViews/Modals/ModalDebCredPixCPAY.vue";
// import ModalEditarAparencia from "@/components/DashViews/Modals/ModalEditarAparencia";
import ModalEditarEnvioEmailCheckinPay from "@/components/DashViews/Modals/ModalEditarEnvioEmailCheckinPay";
import BotaoTutorial from "../material/BotaoTutorial.vue";
//import ModalEditarAparencia from "@/components/DashViews/Modals/ModalEditarAparencia";

export default {
  data: () => ({
    exibirModalEditarEmailAniversario:false,
    exibirModalEditarTermo:false,
    exibirModalEditarTermoLGPD:false,
    exibirModalGeo:false,
    exibirModalEditarFormSanitario:false,
    exibirModalEditarPassosWebCheckin:false,
    exibirModalEditarCheckinPayGeral:false,
    exibirModalFollowUpWebCheckin:false,
    exibirModalBemVindos:false,
    exibirModalEditarPassosWebCheckinPergunta:false,
    exibirModalEditarMensagemFelizAniversario: false,
    exibirModalEditarAPIWhatApp: false,
    exibirModalEditarContaEmail: false,
    exibirModalHITS: false,
    exibirModalWebcheckout: false,
    exibirModalGerais: false,
    exibirModalGaleriaAssinatura: false,
    exibirModalEditarAparencia:false,
    exibirModalEditarEnvioEmail:false,
    isWhatsAppSet: false,
    snack: false,
    snackColor: "",
    snackText: "",
    exibirModalEditarDebCredCPAY:false
  }),
  components:{
    ModalEditarEmailAniversario,
    ModalEditarTermo,
    ModalEditarTermoLGPD,
    ModalEditarGeo,
    ModalEditarFormSanitario,
    ModalEditarPassosWebCheckin,
    ModalEditarCheckinPayGeral,
    ModalFollowupWebCheckin,
    ModalBemVindos,
    ModalEditarPassosWebCheckinPergunta,
    ModalEditarFelizAniversarioWhats,
    ModalEditarAPIWhatsApp,
    ModalEditarContaEmail,
    ModalHITS,
    ModalWebcheckout,
    ModalGerais,
    ModalGaleriaAssinatura,
    // ModalEditarAparencia,
    ModalEditarEnvioEmailCheckinPay,
    ModalDebCredPixCPAY,
    BotaoTutorial
},
  methods: {
    setModalDebCredPix(){
      this.exibirModalEditarDebCredCPAY = !this.exibirModalEditarDebCredCPAY;
    },
    gotoOpcionais(){
      this.$router.push("/dashboard/opcionais")
    },
    gotoGatewaysCheckins(){
      this.$router.push("/dashboard/gatewayscheckinpay")
    },
    setModalEmailAniversario(){
      this.exibirModalEditarEmailAniversario =
        !this.exibirModalEditarEmailAniversario;
    },
    setModalEditarTermo(){	
      this.exibirModalEditarTermo = !this.exibirModalEditarTermo																					 
    },
    setModalEditarTermoLGPD(){	
      this.exibirModalEditarTermoLGPD = !this.exibirModalEditarTermoLGPD																					 
    },
    setModalGeo(){	
      this.exibirModalGeo = !this.exibirModalGeo																					 
    },
    setModalEditarFormSanitario(){
      this.exibirModalEditarFormSanitario = !this.exibirModalEditarFormSanitario
    },
    setModalEditarPassosWebCheckin(){
             this.exibirModalEditarPassosWebCheckin = !this.exibirModalEditarPassosWebCheckin
    },
    setModalEditarCheckinPayGeral(){
             this.exibirModalEditarCheckinPayGeral = !this.exibirModalEditarCheckinPayGeral
    },
    setModalFollowUpWebCheckin(){
             this.exibirModalFollowUpWebCheckin = !this.exibirModalFollowUpWebCheckin
    },
    setModalBemVindos(){
             this.exibirModalBemVindos = !this.exibirModalBemVindos
    },
    setModalEditarPassosWebCheckinPergunta(){
             this.exibirModalEditarPassosWebCheckinPergunta = !this.exibirModalEditarPassosWebCheckinPergunta
    },
    setModalEditarFelizAniversarioWhats() {
      this.exibirModalEditarMensagemFelizAniversario = !this.exibirModalEditarMensagemFelizAniversario;
    },
    setModalEditarAPIWhatsApp() {
      this.exibirModalEditarAPIWhatApp = !this.exibirModalEditarAPIWhatApp;
    },
    setModalEditarContaEmail() {
      this.exibirModalEditarContaEmail = !this.exibirModalEditarContaEmail;
    },
    setModalHITS() {
      this.exibirModalHITS = !this.exibirModalHITS;
    },
    setModalWebcheckout() {
      this.exibirModalWebcheckout = !this.exibirModalWebcheckout;
    },
    setModalGerais() {
      this.exibirModalGerais = !this.exibirModalGerais;
    },
    setModalGaleriaAssinatura() {
      this.exibirModalGaleriaAssinatura = !this.exibirModalGaleriaAssinatura;
    },
    setModalEditarAparencia(){
      this.exibirModalEditarAparencia = !this.exibirModalEditarAparencia
    },
    setModalEditarEnvioEmail(){
      this.exibirModalEditarEnvioEmail = !this.exibirModalEditarEnvioEmail
    },
    buscarDadosAPI() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "APIWHATSAPP",
        })
        .then((resp) => {
          if (resp.status == 200) {
            this.isWhatsAppSet = resp.data[0].valor == "true" ? true : false;
          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    console.log("POR AQUI");
    this.buscarDadosAPI();
  },
};
</script>

<style>
</style>
